import { URLSearchParams } from 'url';

// add routes here so they update everywhere when changed.
export const ROUTES = {
    HOME: '/dashboard',
    PROFILE: '/profile',
    NOTIFICATIONS: '/notifications',
    DASHBOARD: {
        INDEX: '/dashboard',
    },
    TEMPLATES: {
        ROOT: '/documents',
        GENERATE_NEW: '/documents/generate-new',
        GENERATE_NEW_CONFIRM: (id: string) => `/documents/generate-new/${id}`,
        GENERATE_NEW_DOWNLOAD: (id: string) => `/documents/generate-new/${id}/download`,
        CUSTOMER: '/documents/customer',
        ADVANCED: (id: string) => `/documents/customer/${id}`,
        NEW_DOCUMENT: (
            customerId: string,
            templateId: string,
            templateName: string,
            productIds: string[],
            model: string
        ) =>
            `/documents/new-document?customerId=${customerId}&templateId=${templateId}&templateName=${templateName}&productIds=${productIds}&model=${model}`,
    },
    MASTER_DATA: {
        ROOT: '/products',
        PRODUCTS: '/products',
        FINISHED_GOODS: '/products/finished-goods',
        BRAND_MANAGEMENT: '/products/brand-management',
        CUSTOMERS: '/customers',
        CUSTOMERS_CREATE: '/customers/create',
        CUSTOMERS_DETAILS: (id: string) => `/customers/${id}`,
        ITEMS_DETAILS: (id: string) => `/items/${id}`,
        ITEM_CREATE: '/items/create',
        EACH_DETAILS: (id: string) => `/eaches/${id}`,
        EACH_CREATE: (params?: URLSearchParams) => {
            if (params) {
                return `/eaches/create?${params.toString()}`;
            }

            return '/eaches/create';
        },
        PACK_DETAILS: (id: string) => `/packs/${id}`,
        PACK_CREATE: (params?: URLSearchParams) => {
            if (params) {
                return `/packs/create?${params.toString()}`;
            }

            return '/packs/create';
        },
        LOGISTIC_UNIT_DETAILS: (id: string) => `/logistic-units/${id}`,
        LOGISTIC_UNIT_CREATE: (params?: URLSearchParams) => {
            if (params) {
                return `/logistic-units/create?${params.toString()}`;
            }

            return '/logistic-units/create';
        },
    },
    ADMIN: {
        ROOT: '/admin',
        NEW_ORGANIZATION: '/admin/organizations/new',
        ORGANIZATION: {
            GENERAL: (id: string) => `/admin/organizations/${id}`,
            INTEGRATIONS: (id: string) => `/admin/organizations/${id}/integrations`,
            MEMBERS: (id: string) => `/admin/organizations/${id}/members`,
        },
        INTEGRATIONS: {
            ROOT: '/admin/integrations',
            DETAILS: (id: string) => `/admin/integrations/${id}/details`,
            LOGS: (id: string) => `/admin/integrations/${id}/logs`,
            MAPPING: (id: string) => `/admin/integrations/${id}/mapping`,
        },
        SUPER_USER_TOOLS: '/admin/connect/tools',
        SUPER_USER_BULL_BOARD: '/admin/connect/queues/monitor',
    },
    TRADE_PLANNING: {
        PLANS: '/trade-planning',
        BUDGETS: '/trade-planning/budgets',
        BUDGET: (id: string) => `/trade-planning/budgets/${id}`,
        TRADE_PLANS: '/trade-planning/trade-plans',
        CALENDAR: '/trade-planning/calendar',
        CREATE: '/trade-planning/create',
        TRADE_PLAN: (id: string) => `/trade-planning/${id}`,
    },
    DEMAND_PLANNING: {
        ROOT: '/demand-planning',
        PLANS: {
            ROOT: '/demand-planning/plans',
            DETAILS: (id: string) => `/demand-planning/plans/${id}`,
        },
        ADMIN: {
            ROOT: '/demand-planning/admin-settings',
            CREATE: '/demand-planning/admin-settings/create',
            EDIT: (id: string) => `/demand-planning/admin-settings/${id}/edit`,
        },
        DRAFT_FORECAST: {
            ROOT: '/demand-planning/draft-forecasts',
            DETAILS: (id: string) => `/demand-planning/draft-forecasts/${id}`,
            PRODUCT_DETAILS: (id: string, productId: string) =>
                `/demand-planning/draft-forecasts/${id}/product/${productId}`,
        },
        CURRENT_FORECAST: {
            ROOT: '/demand-planning/current-forecast',
            PRODUCT_DETAILS: (productId: string) => `/demand-planning/current-forecast/product/${productId}`,
        },
        CREATE_NEW: '/demand-planning/create-new',
    },
    AUTH: {
        CHECK_EMAIL: '/auth/check-email',
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        RESET_PASSWORD: '/auth/reset-password',
        REQUEST_CODE: '/auth/request-code',
        SIGN_UP: '/auth/signup',
        VERIFY: '/auth/verify',
    },
    MISC: {
        PRIVACY_POLICY: '/privacy-policy',
        TERMS_AND_CONDITIONS: '/tos',
    },
};
